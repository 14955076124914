export const info = {
    phone: {
        link: 'tel:+18288375911',
        text: '(828) 837-5911',
    },
    email: {
        link: 'mailto:laura@murphydentalcenter.com ',
        text: 'laura@murphydentalcenter.com ',
    },
    address: {
        text: '119 Natural Springs Drive, Murphy, North Carolina 28906',
        link: 'https://goo.gl/maps/qJ3GiBXL6Z5EL5ks8',
    },
    social: {
        facebook: 'https://www.facebook.com/murphydentalcenter/',
        yelp: 'https://www.yelp.com/biz/murphy-dental-center-murphy/',
        google: 'https://www.google.com/search?q=murphy+dental+center&oq=murphy+dental+center&aqs=chrome..69i57j69i60l2j0l3.2486j0j7&sourceid=chrome&ie=UTF-8#lrd=0x885ee228df1b5505:0x388da7be083d6e55,1,,,',
    },
};

export type NavLinksTypes = { text: string; link: string; links?: NavLinksTypes }[];

export const navLinks: NavLinksTypes = [
    {
        text: 'our team',
        link: '/our-team/',
    },
    {
        text: 'patient information',
        link: 'patient-information',
        links: [
            {
                text: 'new patient form',
                link: '/patient-information/new-patient-form/',
            },
            {
                text: 'careCredit financing',
                link: '/patient-information/carecredit-financing/',
            },
            {
                text: 'proceed financing',
                link: '/patient-information/proceed-financing/',
            },
            {
                text: 'smile gallery',
                link: '/patient-information/smile-gallery/',
            },
            {
                text: 'fAQs',
                link: '/patient-information/faqs/',
            },
        ],
    },
    {
        text: 'testimonials',
        link: '/testimonials/',
    },
    {
        text: 'contact us',
        link: '/contact-us/',
    },
    {
        text: 'appointment',
        link: '/appointment/',
    },
];

const flatLinks = (() => {
    const flat: NavLinksTypes = [];

    const pushFlat = (arr: NavLinksTypes) =>
        arr.forEach(data => {
            if (!data.links) flat.push(data);
            else {
                flat.push({
                    text: data.text,
                    link: data.link,
                });
                pushFlat(data.links);
            }
        });

    pushFlat(navLinks);
    return flat;
})();

export function getNavLink(text: string) {
    const linkObj = flatLinks.find(data => data.text.toLowerCase() === text.toLowerCase());

    if (linkObj) return linkObj.link;
    else return '';
}

export const footerNavLinks: NavLinksTypes = [
    {
        text: 'our team',
        link: getNavLink('our team'),
    },
    {
        text: 'new patient form',
        link: getNavLink('new patient form'),
    },
    {
        text: 'smile gallery',
        link: getNavLink('smile gallery'),
    },
    {
        text: 'faqs',
        link: getNavLink('faqs'),
    },
    {
        text: 'contact us',
        link: getNavLink('contact us'),
    },
    {
        text: 'privacy policy',
        link: '/privacy-policy/',
    },
];
