exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-appointment-tsx": () => import("./../../../src/pages/appointment.tsx" /* webpackChunkName: "component---src-pages-appointment-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-team-tsx": () => import("./../../../src/pages/our-team.tsx" /* webpackChunkName: "component---src-pages-our-team-tsx" */),
  "component---src-pages-patient-information-carecredit-financing-tsx": () => import("./../../../src/pages/patient-information/carecredit-financing.tsx" /* webpackChunkName: "component---src-pages-patient-information-carecredit-financing-tsx" */),
  "component---src-pages-patient-information-faqs-tsx": () => import("./../../../src/pages/patient-information/faqs.tsx" /* webpackChunkName: "component---src-pages-patient-information-faqs-tsx" */),
  "component---src-pages-patient-information-new-patient-form-tsx": () => import("./../../../src/pages/patient-information/new-patient-form.tsx" /* webpackChunkName: "component---src-pages-patient-information-new-patient-form-tsx" */),
  "component---src-pages-patient-information-proceed-financing-tsx": () => import("./../../../src/pages/patient-information/proceed-financing.tsx" /* webpackChunkName: "component---src-pages-patient-information-proceed-financing-tsx" */),
  "component---src-pages-patient-information-smile-gallery-tsx": () => import("./../../../src/pages/patient-information/smile-gallery.tsx" /* webpackChunkName: "component---src-pages-patient-information-smile-gallery-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */)
}

