import CLink from '@/CLink';
import { SecondaryBtn } from '@/buttons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SkipNavLink } from '@reach/skip-nav';
import '@reach/skip-nav/styles.css';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment, useEffect, useRef, useState } from 'react';
import { DesktopSubNav } from './DesktopSubNav';
import { MobNav } from './MobNav';
import { navLinkStyles } from './NavLinkStyles';
import { getNavLink, navLinks } from './NavLinks';
import { flexCenter, flexSpace, px } from './styles/classes';

const Navbar = styled.header<{ scrolled: boolean }>`
    ${px};
    ${flexSpace};
    padding-top: 16px;
    padding-bottom: 16px;
    justify-content: space-between;
    z-index: 90;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0 auto;
    border-bottom: 1px solid transparent;
    background-color: rgba(255, 255, 255, 0.7);

    @media (min-width: 1024px) {
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: rgba(255, 255, 255, 0.85);
    }

    @media (min-width: 1900px) {
        padding: 20px calc((100vw - 1792px) / 2);
    }

    ${({ scrolled, theme }) =>
        scrolled &&
        css`
            background-color: ${theme.colors.white} !important;
            border-color: ${theme.colors.gray};
        `};
`;

export const logoStyles = css`
    transition: opacity 0.3s ease-in-out;

    :hover {
        opacity: 0.7;
    }

    :focus-visible {
        opacity: 0.7;
    }

    @media (max-width: 1023px) {
        width: 95.5px;
        height: 50.5px;
    }
`;

const DesktopNav = styled.nav`
    ${flexCenter};
    margin-left: auto;

    > * {
        margin-right: 72px;
    }

    @media (max-width: 1023px) {
        display: none;
    }
`;

const Observe = styled.span`
    position: absolute;
    width: 1px;
    height: 1px;
    top: 10px;
    left: 0;
`;

const BookBtn = styled(SecondaryBtn)`
    @media (max-width: 1279px) {
        display: none;
    }
`;

export const Nav = () => {
    const [scrolled, setScrolled] = useState(false);

    const observeRef = useRef<HTMLElement | null>(null);

    const visible = useIntersectionObserver(observeRef, {});

    useEffect(() => {
        const timeout = setTimeout(() => {
            setScrolled(!visible);
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, [visible]);

    return (
        <Fragment>
            <Navbar scrolled={scrolled}>
                <CLink to="/">
                    <StaticImage
                        src="../../../assets/images/layout/logo.svg"
                        alt="murphy dental center"
                        css={logoStyles}
                    />
                </CLink>
                <SkipNavLink />

                <DesktopNav>
                    {navLinks.slice(0, -1).map((data, i) =>
                        data.links ? (
                            <DesktopSubNav text={data.text} links={data.links} key={i} />
                        ) : (
                            <CLink to={data.link} css={navLinkStyles} key={i}>
                                {data.text}
                            </CLink>
                        )
                    )}
                </DesktopNav>

                <BookBtn as={CLink} to={getNavLink('appointment')}>
                    Book Now
                </BookBtn>

                <MobNav />
            </Navbar>
            <Observe ref={observeRef} />
        </Fragment>
    );
};
