type Seo = {
    [index: string]: {
        t: string;
        d?: string;
    };
};

export const seoData: Seo = {
    'our-team': {
        t: 'Cosmetic Dentist Team in North Carolina - Murphy Dental Center',
        d: 'At Murphy Dental Center our dental practice is a friendly team of professionals in North Carolina that are dedicated to your utmost comfort and optimal oral health.',
    },
    testimonials: {
        t: 'Read Our North Carolina Murphy Dental Center Testimonials',
        d: 'A large number of patient testimonials on this page reveals why Murphy Dental Center is considered the best Dental Center in Murphy, North Carolina by patients.',
    },
    'contact-us': {
        t: 'Contact Cosmetic Dental Services Near Murphy North Carolina',
        d: 'If you are searching for a good cosmetic dental clinic near you. Murphy Dental Center in North Carolina is the perfect place for you. Contact our dental office.',
    },
    appointment: {
        t: 'Get An Appointment For Cosmetic Dental Services in Murphy NC',
        d: 'The first step towards a beautiful, healthy smile is to schedule an appointment for Cosmetic Dental Services in Murphy, North Carolina. Contact Us Today!',
    },
    faq: {
        t: 'FAQ - Murphy North Carolina Dental Center Services',
        d: 'Below are some of the most frequently asked questions patients have about dentistry and oral health issues.FAQ - Murphy North Carolina Dental Center Services.',
    },
    'new-patient-form': {
        t: 'New Patient Form - Murphy Dental Center in North Carolina',
        d: 'If you are a new patient to our office, fill our new patient forms Murphy Dental Center in NC that will need to be filled out before you arrive at our office.',
    },
    'carecredit-financing': {
        t: 'CareCredit Healthcare Financing Solution in  NC - Murphy Dental Center',
        d: 'CareCredit is a credit card that helps you pay for treatments and procedures for you or your family that may not be covered by insurance. Learn more.',
    },
    'smile-gallery': {
        t: 'Patient Treatments Smile Gallery Murphy Dental Center',
        d: 'Our Smile Gallery offers patients edited pictures from their treatment that can be shared with family and friends. See before & after photos of your Treatments.',
    },
};
